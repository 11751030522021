<template>
  <div v-if="step >= 0" class="custom-steps">
    <el-steps :active="step" align-center>
      <el-step :title="$t('52b1e86')" icon="el-icon-remove" class="step-item">
        <template v-slot:description>
          <p class="time">{{ data.signUpTime }}</p>
          <!-- <p v-if="step === 1" class="desc-item">
            已报名: {{ data.signUpNum }}
          </p>
          <p v-if="step === 1" class="desc-item">
            已沟通: {{ data.communicationNum }}
          </p> -->
        </template>
      </el-step>
      <el-step v-if="showMiddleStep" :title="$t('6bb96c5')" icon="el-icon-remove">
        <template v-slot:description>
          <p class="time">{{ data.cooperationStartTime }}</p>
          <!-- <p v-if="step > 1" class="desc-item">合作方: {{ data.signUpNum }}</p> -->
        </template>
      </el-step>
      <el-step v-if="!isEnd" :title="$t('c3c076c')" icon="el-icon-remove">
        <template v-slot:description>
          <p class="time">{{ data.cooperationEndTime }}</p>
        </template>
      </el-step>
      <el-step v-if="isEnd" title="已终止" icon="el-icon-remove">
        <template v-slot:description>
          <p class="time">{{ data.cooperationEndTime }}</p>
          <p class="desc-item">终止方：{{ data.endPerson }}</p>
        </template>
      </el-step>
    </el-steps>
  </div>
</template>

<script>
export default {
  name: "Step",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    step() {
      switch (this.data.status) {
        case "NOT_COOPERATED":
          return -1
        case "TO_BE_COMMUNICATED":
        case "SERVICE_COMMUNICATED":
          return 1
        case "COOPERATION":
          return 2
        case "END":
        case "USER_END":
          return 0
        case "COMPLETED":
          return 3
        default:
          return 0
      }
    },
    showMiddleStep() {
      return !(this.isEnd && !this.data.cooperationStartTime)
    },
    isEnd() {
      return this.data.status === "END" || this.data.status === "USER_END"
    },
  },
}
</script>

<style scoped lang="less">
.custom-steps {
  padding: 20px;
  background: rgba(0, 164, 255, 0.04);
  margin-bottom: 20px;
  /deep/ .el-step__title {
    color: #666666;
    font-weight: 600;
  }
  /deep/ .el-step__head.is-finish {
    .el-step__title {
      color: #000000;
    }
  }
  /deep/ .el-step__icon {
    background: #f5fcff;
    width: 18px;
    height: 18px;
    .el-step__icon-inner::before {
      content: "";
      display: block;
      background: rgba(0, 164, 255, 1);
      border-radius: 6px;
      width: 10px;
      height: 10px;
    }
  }
  /deep/ .el-step__head.is-process {
    .el-step__icon-inner::before {
      content: "";
      display: block;
      background: #b0babf;
    }
  }
  /deep/ .el-step__head.is-wait {
    .el-step__icon-inner::before {
      background: #b0babf;
    }
  }
  /deep/ .el-step__line {
    top: 8.5px;
  }
  .time {
    font-size: 14px;
    color: #333333;
    line-height: 30px;
  }
  .desc-item {
    line-height: 30px;
  }
}
</style>
